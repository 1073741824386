import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, Linkedin } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-gray-300 py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Footer Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {/* Logo Column */}
          <div>
            <Link to="/" className="text-2xl font-bold text-white">
              ModulawAI
            </Link>
          </div>

          {/* Support */}
          <div>
            <h3 className="text-white font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              <li className="flex items-center">
                <span className="text-gray-400">Documentation</span>
                <span className="ml-1.5 px-1.5 py-0.5 text-[10px] font-medium bg-gray-700 text-gray-300 rounded-full">Coming Soon</span>
              </li>
              <li className="flex items-center">
                <span className="text-gray-400">Guides</span>
                <span className="ml-1.5 px-1.5 py-0.5 text-[10px] font-medium bg-gray-700 text-gray-300 rounded-full">Coming Soon</span>
              </li>
              <li><a href="/contact" className="hover:text-white">Contact</a></li>
            </ul>
          </div>

          {/* Company */}
          <div>
            <h3 className="text-white font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/about" className="hover:text-white">About Us</Link>
              </li>
              <li>
                <Link to="/investors" className="hover:text-white flex items-center">
                  Investors
                  <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">New</span>
                </Link>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div>
            <h3 className="text-white font-semibold mb-4">Legal</h3>
            <ul className="space-y-2">
              <li><Link to="/privacy" className="hover:text-white">Privacy Policy</Link></li>
              <li><Link to="/terms" className="hover:text-white">Terms of Service</Link></li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 mt-12 pt-8 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm">© {new Date().getFullYear()} ModulawAI. All rights reserved.</p>
          
          {/* Social Links */}
          <div className="flex space-x-6 mt-4 md:mt-0">
            <a href="https://instagram.com/modulawai" className="hover:text-white" aria-label="Instagram">
              <Instagram size={20} />
            </a>
            <a href="https://www.linkedin.com/company/modulawai/" className="hover:text-white" aria-label="LinkedIn">
              <Linkedin size={20} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer; 