import React, { useEffect, useState } from 'react';

const Logo = ({ 
  className = '', 
  width = '150', 
  height = '150', 
  isDark = false
}) => {
  const [currentTheme, setCurrentTheme] = useState(isDark);

  // Sync with actual theme state
  useEffect(() => {
    const updateTheme = () => {
      const isDarkMode = document.documentElement.classList.contains('dark');
      setCurrentTheme(isDarkMode);
    };

    // Initial check
    updateTheme();

    // Listen for theme changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          updateTheme();
        }
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['class']
    });

    return () => observer.disconnect();
  }, []);

  const DARK_LOGO = '/Modulaw-Logo-dark.png';
  const LIGHT_LOGO = '/Modulaw-Logo-light.png';

  // Use appropriate logo based on theme
  const logoSrc = currentTheme ? DARK_LOGO : LIGHT_LOGO;

  return (
    <img
      src={logoSrc}
      alt="Modulaw AI Logo"
      className={className}
      width={width}
      height={height}
      loading="eager"
      // Add caching hints
      fetchpriority="high"
      // Add cache control headers
      crossOrigin="anonymous"
      onError={(e) => {
        console.error('Logo failed to load:', e);
        // Try to load the opposite logo as fallback
        e.target.src = currentTheme ? LIGHT_LOGO : DARK_LOGO;
      }}
    />
  );
};

export default React.memo(Logo); // Memoize the component to prevent unnecessary re-renders