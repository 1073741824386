export const solutionsData = {
  "advocates-and-litigators": {
    title: "Advocates & Litigators",
    overview: "Advocates and litigators represent clients in legal disputes, providing counsel and arguing cases in court.",
    challenges: [
      {
        title: "Case Documentation Management",
        description: "Managing vast amounts of case files, evidence, and legal documents while ensuring quick retrieval and proper organization."
      },
      {
        title: "Court Schedule Coordination",
        description: "Balancing multiple court appearances, client meetings, and filing deadlines across different jurisdictions and time zones."
      },
      {
        title: "Legal Research Efficiency",
        description: "Staying current with legal precedents, case law, and regulatory changes while efficiently finding relevant information for cases."
      },
      {
        title: "Client Communication",
        description: "Maintaining clear and consistent communication with clients about case progress, updates, and strategic decisions."
      },
      {
        title: "Evidence Organization",
        description: "Organizing and analyzing large volumes of evidence while maintaining proper chain of custody and accessibility."
      },
      {
        title: "Strategic Case Planning",
        description: "Developing and adapting case strategies based on evolving circumstances and new information."
      }
    ],
    solutions: {
      "Smart Case Management": "Centralize and organize all case-related documents with AI-powered search and categorization",
      "Intelligent Scheduling": "Automate calendar management with smart conflict detection and priority-based scheduling",
      "Advanced Legal Research": "Access comprehensive legal databases with AI-assisted research tools and precedent analysis",
      "Client Portal": "Provide secure, 24/7 access to case updates, documents, and communication channels"
    },
    benefits: [
      "40% reduction in time spent on document management",
      "Improved case preparation with AI-powered insights",
      "Enhanced client satisfaction through better communication",
      "Streamlined workflow and reduced administrative overhead"
    ]
  },
  "judicial-officers": {
    title: "Judicial Officers",
    overview: "Judicial officers, including judges and magistrates, oversee legal proceedings and ensure justice is administered fairly.",
    challenges: [
      {
        title: "Case Volume Management",
        description: "Processing and reviewing large volumes of cases while maintaining thorough attention to detail and legal principles."
      },
      {
        title: "Docket Optimization",
        description: "Managing court schedules efficiently while accommodating urgent matters and ensuring fair time allocation."
      },
      {
        title: "Precedent Analysis",
        description: "Maintaining consistency in rulings while considering complex precedents and evolving legal frameworks."
      },
      {
        title: "Document Review",
        description: "Efficiently reviewing extensive case files, submissions, and evidence while identifying key legal issues."
      },
      {
        title: "Research Verification",
        description: "Verifying citations, legal principles, and precedents cited in submissions for accuracy and relevance."
      },
      {
        title: "Decision Documentation",
        description: "Creating comprehensive, well-reasoned judgments while managing time constraints and case backlogs."
      }
    ],
    solutions: {
      "AI-Powered Case Analysis": "Quickly analyze case documents and identify key legal issues and relevant precedents",
      "Smart Docket Management": "Optimize court schedules with intelligent case prioritization and resource allocation",
      "Precedent Database": "Access comprehensive database of previous decisions with AI-assisted similarity analysis",
      "Judgment Writing Assistant": "Generate structured judgment drafts with automated citation and precedent verification"
    },
    benefits: [
      "50% faster case review and analysis",
      "Improved consistency in judicial decisions",
      "Reduced case backlog through efficient scheduling",
      "Enhanced accuracy in legal research and citation"
    ]
  },
  "corporate-lawyers": {
    title: "Corporate & Transactional Lawyers",
    overview: "Corporate lawyers handle legal aspects of business operations, including contracts, mergers, and compliance.",
    challenges: [
      {
        title: "Complex Contract Management",
        description: "Drafting and managing complex contracts across multiple jurisdictions and stakeholders, requiring meticulous attention to detail and constant updates."
      },
      {
        title: "Regulatory Compliance",
        description: "Ensuring compliance with evolving regulations across different jurisdictions while managing risk and maintaining business efficiency."
      },
      {
        title: "Stakeholder Coordination",
        description: "Coordinating between multiple stakeholders including executives, board members, regulators, and external counsel for timely decision-making."
      },
      {
        title: "Due Diligence",
        description: "Managing comprehensive due diligence processes for mergers and acquisitions, requiring analysis of vast amounts of documentation."
      },
      {
        title: "Document Version Control",
        description: "Maintaining accurate version control of legal documents while collaborating with multiple parties and tracking changes effectively."
      },
      {
        title: "Risk Assessment",
        description: "Conducting thorough risk assessments for business decisions while balancing legal compliance with business objectives."
      }
    ],
    solutions: {
      "Contract Automation": "Generate and manage contracts with customizable templates, automated workflows, and intelligent version control",
      "Compliance Monitoring": "Stay updated on regulatory changes with real-time alerts and automated compliance checks",
      "Collaboration Platform": "Facilitate secure communication and document sharing among legal teams and stakeholders",
      "Due Diligence Assistant": "Streamline M&A due diligence with AI-powered document analysis and risk assessment"
    },
    benefits: [
      "60% faster contract creation and review",
      "Reduced compliance risks through automated monitoring",
      "Enhanced collaboration and stakeholder management",
      "Streamlined due diligence processes"
    ]
  },
  "specialized-lawyers": {
    title: "Specialized Lawyers",
    overview: "Specialized lawyers focus on niche areas such as intellectual property, tax, real estate, human rights, and environmental law.",
    challenges: [
      {
        title: "Domain Expertise Maintenance",
        description: "Staying current with rapidly evolving specialized legal domains and industry-specific regulations."
      },
      {
        title: "Technical Documentation",
        description: "Managing complex technical documentation and specialized filing requirements unique to each practice area."
      },
      {
        title: "Cross-Domain Coordination",
        description: "Coordinating with technical experts, industry specialists, and regulatory bodies while maintaining legal perspective."
      },
      {
        title: "Specialized Research",
        description: "Conducting in-depth research in specialized legal areas with limited precedents or evolving frameworks."
      },
      {
        title: "Industry Compliance",
        description: "Ensuring compliance with industry-specific regulations while adapting to technological and market changes."
      },
      {
        title: "Expert Communication",
        description: "Translating complex technical and legal concepts for clients while maintaining accuracy and clarity."
      }
    ],
    solutions: {
      "Specialized Knowledge Base": "Access comprehensive databases tailored to specific legal domains with AI-powered updates",
      "Technical Document Management": "Streamline specialized document handling with industry-specific templates and workflows",
      "Expert Network Platform": "Connect with relevant industry experts and specialists through secure collaboration tools",
      "Custom Compliance Tools": "Monitor and manage industry-specific compliance requirements with automated tracking"
    },
    benefits: [
      "Enhanced expertise through specialized knowledge management",
      "Improved efficiency in technical documentation",
      "Better collaboration with industry experts",
      "Streamlined compliance management for specific sectors"
    ]
  }
}; 