import React, { useState, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSun, FaMoon } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../store/authSlice';
import { ChevronDown, ChevronUp } from 'lucide-react';
import NotificationBell from './NotificationBell';
import Logo from './Logo';
import { solutionsData } from '../data/solutionsData';

const Navbar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    // Initialize from localStorage or system preference
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(false);
  const [isMobileProductsExpanded, setIsMobileProductsExpanded] = useState(false);
  const [isMobileSolutionsExpanded, setIsMobileSolutionsExpanded] = useState(false);

  // Memoize the auth check to prevent unnecessary recalculations
  const isAuthenticated = useMemo(() => Boolean(user?.id || user?._id), [user]);

  // Sync theme with document and localStorage
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  }, [isDarkMode]);

  const toggleTheme = () => {
    setIsDarkMode(prev => !prev);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <nav className="bg-white dark:bg-gray-800 shadow-sm fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4">
        <div className="grid grid-cols-12 items-center h-14 sm:h-16 md:h-18 lg:h-20">
          {/* Left Section - Logo */}
          <div className="col-span-6 md:col-span-2 lg:col-span-2 flex items-center">
            <Link to="/" className="flex items-center">
              <Logo 
                width="32" 
                height="32" 
                className="h-5 w-auto sm:h-6 md:h-7 lg:h-8"
                isDark={isDarkMode}
              />
            </Link>
          </div>

          {/* Middle Section - Navigation Links */}
          <div className="hidden md:block md:col-span-7 lg:col-span-8">
            <div className="flex items-center justify-center md:space-x-4 lg:space-x-8">
              {!isAuthenticated && (
                <>
                  {/* Products Dropdown */}
                  <div className="relative">
                    <button
                      onMouseEnter={() => setIsProductsDropdownOpen(true)}
                      onClick={() => setIsProductsDropdownOpen(!isProductsDropdownOpen)}
                      className="inline-flex items-center px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400 transition-colors"
                    >
                      Products
                      <svg className="w-4 h-4 md:w-5 md:h-5 ml-1 md:ml-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    
                    {/* Products Dropdown Menu */}
                    {isProductsDropdownOpen && (
                      <div
                        onMouseLeave={() => setIsProductsDropdownOpen(false)}
                        className="absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5"
                      >
                        <div className="py-2">
                          <Link
                            to="/products/legal-assistant"
                            className="block px-4 py-3 text-base text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 hover:text-blue-600 dark:hover:text-blue-400"
                            onClick={() => setIsProductsDropdownOpen(false)}
                          >
                            <div className="font-medium">AI Legal Assistant</div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Intelligent legal research and document analysis</p>
                          </Link>
                          <Link
                            to="/products/case-management"
                            className="block px-4 py-3 text-base text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 hover:text-blue-600 dark:hover:text-blue-400"
                            onClick={() => setIsProductsDropdownOpen(false)}
                          >
                            <div className="font-medium">Case Management</div>
                            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">Comprehensive case and client management</p>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Solutions Dropdown */}
                  <div className="relative">
                    <button
                      onMouseEnter={() => setIsDropdownOpen(true)}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      className="inline-flex items-center px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400 transition-colors"
                    >
                      Solutions
                      <svg className="w-4 h-4 md:w-5 md:h-5 ml-1 md:ml-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    
                    {/* Dropdown Menu */}
                    {isDropdownOpen && (
                      <div
                        onMouseLeave={() => setIsDropdownOpen(false)}
                        className="absolute left-0 mt-2 w-72 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5"
                      >
                        <div className="py-2">
                          {Object.entries(solutionsData).map(([slug, data]) => (
                            <Link
                              key={slug}
                              to={`/solutions/${slug}`}
                              className="block px-4 py-3 text-base text-gray-700 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600 hover:text-blue-600 dark:hover:text-blue-400"
                              onClick={() => setIsDropdownOpen(false)}
                            >
                              {data.title}
                            </Link>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <Link to="/pricing" className="px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400 transition-colors">
                    Pricing
                  </Link>
                </>
              )}
            </div>
          </div>

          {/* Right Section - Auth Links & Theme Toggle */}
          <div className="col-span-6 md:col-span-3 lg:col-span-2 flex items-center justify-end">
            <div className="flex items-center md:space-x-2 lg:space-x-4">
              <button
                onClick={toggleTheme}
                className="p-2 text-gray-600 hover:text-blue-600 dark:text-gray-300 dark:hover:text-blue-400 transition-colors"
                aria-label="Toggle theme"
              >
                {isDarkMode ? (
                  <FaSun className="w-4 h-4 md:w-5 md:h-5" />
                ) : (
                  <FaMoon className="w-4 h-4 md:w-5 md:h-5" />
                )}
              </button>

              <div className="hidden md:flex items-center md:space-x-2 lg:space-x-4">
                {isAuthenticated ? (
                  <>
                    <NotificationBell />
                    <Link
                      to="/chat"
                      className="px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400 transition-colors whitespace-nowrap"
                    >
                      Chat
                    </Link>
                    <button
                      onClick={handleLogout}
                      className="px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-white bg-red-600 hover:bg-red-700 rounded-md transition-colors whitespace-nowrap"
                    >
                      Logout
                    </button>
                  </>
                ) : (
                  <>
                    <Link
                      to="/login"
                      className="px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-gray-700 hover:text-blue-600 dark:text-gray-200 dark:hover:text-blue-400 transition-colors whitespace-nowrap"
                    >
                      Login
                    </Link>
                    <Link
                      to="/signup"
                      className="px-2 md:px-3 lg:px-4 py-2 text-base md:text-lg lg:text-xl font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md transition-colors whitespace-nowrap"
                    >
                      Sign up
                    </Link>
                  </>
                )}
              </div>

              {/* Mobile Menu Button */}
              <button
                onClick={toggleMobileMenu}
                className="md:hidden p-2 text-gray-400 hover:text-blue-600 dark:hover:text-blue-400 transition-colors ml-2"
                aria-expanded={isMobileMenuOpen}
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="h-5 w-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Menu Panel */}
      <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:hidden border-t border-gray-200 dark:border-gray-700`}>
        <div className="px-3 pt-3 pb-4 space-y-2 bg-white dark:bg-gray-800 shadow-lg">
          <button
            onClick={toggleTheme}
            className="w-full text-left px-4 py-3 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700"
          >
            {isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
          </button>

          {isAuthenticated ? (
            <>
              <NotificationBell />
              <Link
                to="/chat"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-4 py-3 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Chat
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  setIsMobileMenuOpen(false);
                }}
                className="w-full text-left px-4 py-3 rounded-md text-base font-medium text-red-600 hover:text-red-700 hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                Logout
              </button>
            </>
          ) : (
            <>
              {/* Mobile Products Menu */}
              <div className="border-b border-gray-200 dark:border-gray-700">
                <button
                  onClick={() => setIsMobileProductsExpanded(!isMobileProductsExpanded)}
                  className="w-full flex items-center justify-between px-4 py-3 text-base font-medium text-gray-700 dark:text-gray-200"
                >
                  Products
                  {isMobileProductsExpanded ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </button>
                {isMobileProductsExpanded && (
                  <div className="pl-4 pb-2">
                    <Link
                      to="/products/legal-assistant"
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="block px-4 py-2 text-base text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                    >
                      <div className="font-medium">AI Legal Assistant</div>
                      <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                        Intelligent legal research and document analysis
                      </p>
                    </Link>
                    <Link
                      to="/products/case-management"
                      onClick={() => setIsMobileMenuOpen(false)}
                      className="block px-4 py-2 text-base text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                    >
                      <div className="font-medium">Case Management</div>
                      <p className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">
                        Comprehensive case and client management
                      </p>
                    </Link>
                  </div>
                )}
              </div>

              {/* Mobile Solutions Menu */}
              <div className="border-b border-gray-200 dark:border-gray-700">
                <button
                  onClick={() => setIsMobileSolutionsExpanded(!isMobileSolutionsExpanded)}
                  className="w-full flex items-center justify-between px-4 py-3 text-base font-medium text-gray-700 dark:text-gray-200"
                >
                  Solutions
                  {isMobileSolutionsExpanded ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </button>
                {isMobileSolutionsExpanded && (
                  <div className="pl-4 pb-2">
                    {Object.entries(solutionsData).map(([slug, data]) => (
                      <Link
                        key={slug}
                        to={`/solutions/${slug}`}
                        onClick={() => setIsMobileMenuOpen(false)}
                        className="block px-4 py-2 text-base text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400"
                      >
                        {data.title}
                      </Link>
                    ))}
                  </div>
                )}
              </div>

              <Link
                to="/pricing"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-4 py-3 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Pricing
              </Link>
              <Link
                to="/login"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-4 py-3 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 dark:text-gray-200 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Login
              </Link>
              <Link
                to="/signup"
                onClick={() => setIsMobileMenuOpen(false)}
                className="block px-4 py-3 text-base font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-md"
              >
                Sign up
              </Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;