import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => {
  const location = useLocation();
  
  // Array of paths where navbar and footer should not appear
  const noHeaderFooterRoutes = ['/chat', '/profile'];
  
  // Check if current path should have navbar/footer
  const shouldShowHeaderFooter = !noHeaderFooterRoutes.includes(location.pathname);

  return (
    <div className="flex flex-col min-h-screen">
      {shouldShowHeaderFooter && <Navbar />}
      <main className={`flex-grow ${shouldShowHeaderFooter ? 'mt-14 sm:mt-16 md:mt-18 lg:mt-20 bg-inherit' : ''}`}>
        {children}
      </main>
      {shouldShowHeaderFooter && <Footer />}
    </div>
  );
};

export default Layout; 