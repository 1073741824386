import React, { lazy, Suspense, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/gradient.css';
import { useInView } from 'react-intersection-observer';

// Lazy load the VimeoPlayer component since it's not needed immediately
const VimeoPlayer = lazy(() => import('./VimeoPlayer'));

// Loading placeholder for lazy-loaded components
const LoadingPlaceholder = () => (
  <div className="w-full h-64 bg-gray-200 dark:bg-gray-700 animate-pulse rounded-2xl"></div>
);

// Optimized FeatureCard component with intersection observer
const FeatureCard = ({ feature, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '50px'
  });

  return (
    <div
      ref={ref}
      className={`bg-white dark:bg-gray-700 rounded-xl p-6 md:p-8 hover:bg-gray-50 dark:hover:bg-gray-600 
        transition-all duration-300 shadow-lg hover:shadow-xl transform hover:-translate-y-1
        ${inView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-4'}`}
      style={{ transitionDelay: `${index * 100}ms` }}
    >
      <div className="text-4xl md:text-5xl mb-4 md:mb-6">{feature.icon}</div>
      <h3 className="text-lg md:text-xl font-bold mb-2 md:mb-3 text-gray-900 dark:text-white">
        {feature.title}
      </h3>
      <p className="text-sm md:text-base text-gray-600 dark:text-gray-300">
        {feature.description}
      </p>
    </div>
  );
};

// Optimized FAQ component with lazy expansion
const FAQItem = ({ question, answer, index }) => {
  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true,
    rootMargin: '50px'
  });

  return (
    <div 
      ref={ref}
      className={`py-5 transition-opacity duration-500 ${inView ? 'opacity-100' : 'opacity-0'}`}
      style={{ transitionDelay: `${index * 50}ms` }}
    >
      <details className="group">
        <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
          <span className="text-gray-900 dark:text-white">{question}</span>
          <span className="transition group-open:rotate-180">
            <svg
              fill="none"
              height="24"
              shapeRendering="geometricPrecision"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              width="24"
              className="text-gray-700 dark:text-gray-300"
            >
              <path d="M6 9l6 6 6-6"></path>
            </svg>
          </span>
        </summary>
        <p className="text-neutral-600 dark:text-neutral-300 mt-3 group-open:animate-fadeIn">
          {answer}
        </p>
      </details>
    </div>
  );
};

const HomePage = () => {
  const navigate = useNavigate();

  // Memoize static data
  const features = useMemo(() => [
    {
      title: "Comprehensive Legal Database",
      description: "Access Nigeria's largest digital library of court judgments, including Supreme Court, Court of Appeal, Federal High Court, and State High Court decisions.",
      icon: "📚"
    },
    {
      title: "AI-Powered Legal Research",
      description: "Leverage advanced AI to analyze case law, identify precedents, and extract key legal principles with unmatched accuracy and speed.",
      icon: "🤖"
    },
    {
      title: "Intelligent Document Analysis",
      description: "Upload legal documents for instant AI analysis, case citations, and relevant statutory provisions with our advanced legal analytics.",
      icon: "📄"
    },
    {
      title: "Practice Management Suite",
      description: "Streamline your legal practice with integrated case tracking, deadline management, and automated legal document generation.",
      icon: "⚖️"
    }
  ], []);

  const faqItems = useMemo(() => [
    {
      question: "What makes ModulawAI different from traditional legal research tools?",
      answer: "ModulawAI combines Nigeria's largest digital legal database with advanced AI technology to provide instant, comprehensive legal analysis. Unlike traditional tools, our AI understands legal context, identifies relevant precedents across jurisdictions, and provides actionable insights for your cases."
    },
    {
      question: "How accurate is ModulawAI's legal analysis?",
      answer: "ModulawAI is trained on millions of legal documents and validated by experienced Nigerian lawyers. Our AI achieves over 95% accuracy in case law analysis and citation matching. The system is continuously updated with the latest judicial decisions and statutory amendments, ensuring you always have access to current legal information. However, we recommend using it as a powerful complement to your professional expertise."
    },
    {
      question: "What subscription plans do you offer?",
      answer: "We offer flexible subscription plans including monthly and annual options, with different tiers for individual practitioners, law firms, and organizations. Any user can start with a 3-day free trial, allowing you to experience the full platform before committing."
    },
    {
      question: "How do you keep legal information up-to-date?",
      answer: "Our database is continuously updated with the latest court decisions, legislation changes, and legal developments. We maintain direct partnerships with courts and legal institutions to ensure timely updates to our knowledge base."
    },
    {
      question: "Is my data secure?",
      answer: "Yes, we implement bank-grade security measures including end-to-end encryption for all communications. Your client information and legal documents are stored securely and never shared with third parties."
    },
    {
      question: "Can I upload my own documents for analysis?",
      answer: "Yes, you can upload legal documents in various formats (PDF, DOC, DOCX) for AI-powered analysis. The system will analyze the content and provide relevant insights, citations, and cross-references."
    },
    {
      question: "Do you offer training and support?",
      answer: "Yes, we provide comprehensive onboarding training, video tutorials, and dedicated customer support. Our support team is available during business hours to assist with any questions or technical issues."
    },
    {
      question: "Can I cancel my subscription anytime?",
      answer: "Yes, you can cancel your subscription at any time through your account dashboard. For annual subscriptions, we offer prorated refunds for the unused portion of your service."
    },
    {
      question: "How does ModulawAI handle legal document analysis?",
      answer: "Our AI can analyze various legal documents including contracts, pleadings, and judgments. It automatically identifies key legal issues, extracts relevant citations, cross-references applicable statutes, and suggests relevant precedents. This helps you quickly understand document implications and strengthen your legal position."
    },
    {
      question: "Can ModulawAI assist with litigation strategy?",
      answer: "Yes, ModulawAI provides strategic insights by analyzing similar cases, identifying successful arguments, and highlighting key factors that influenced judicial decisions. It helps you build stronger cases by finding relevant precedents and understanding how courts have ruled on similar issues."
    }
  ], []);

  // Intersection observer for hero section
  const [heroRef, heroInView] = useInView({
    threshold: 0,
    triggerOnce: true
  });

  return (
    <div className="min-h-screen">
      {/* Hero Section - Static Blue Background */}
      <section 
        ref={heroRef}
        className={`relative min-h-[85vh] flex items-center bg-blue-700 transition-opacity duration-700 ${
          heroInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className="absolute inset-0 bg-blue-700"></div>
        
        <div className="relative z-10 w-full max-w-7xl mx-auto px-4 flex flex-col justify-center items-center text-center">
          <div className="w-full">
            <h1 className="text-3xl sm:text-4xl lg:text-5xl xl:text-6xl font-bold text-white mb-6 leading-tight">
              Streamline Your Legal Practice with{' '}
              <span className="text-blue-200">AI</span>
            </h1>
            <p className="text-base sm:text-lg text-white/90 mb-8 max-w-2xl mx-auto leading-relaxed">
              Manage your entire case lifecycle, from research to rulings. Access AI-powered legal research powered with over 10,000+ judgments, statutes, precedents, document management, and intelligent case tracking - all in one unified platform.
            </p>
            <div className="flex flex-col sm:flex-row justify-center gap-4">
              <button 
                onClick={() => navigate('/signup')}
                className="bg-white text-blue-700 px-6 sm:px-8 py-3 rounded-lg 
                  text-base font-semibold transition-colors hover:bg-gray-100"
              >
                Start Free Trial
              </button>
              <button 
                onClick={() => navigate('/contact')}
                className="bg-blue-600/30 text-white border border-white/30
                  px-6 sm:px-8 py-3 rounded-lg text-base font-semibold transition-colors hover:bg-blue-600/40"
              >
                Schedule Demo
              </button>
            </div>
            <p className="text-sm text-white/80 mt-4 italic">
              *Both the AI Legal Assistant and Case Manager are currently in alpha development. Some features may be limited or not fully functional.
            </p>
          </div>
        </div>
      </section>

      {/* Video Section */}
      <section className="py-12 md:py-16 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-800">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Don't Take Our Word For It
            </h2>
            <p className="text-lg sm:text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Watch ModulawAI in action as it transforms complex legal research into instant insights
            </p>
          </div>

          <Suspense fallback={<LoadingPlaceholder />}>
            <VimeoPlayer 
              videoId="1034206511"
              videoHash="7c51122a92"
              onPlay={() => console.log('Video started playing')}
            />
          </Suspense>
        </div>
      </section>

      {/* Features Grid */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="max-w-7xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Powerful Features for Modern Legal Practice
            </h2>
            <p className="text-lg text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
              Everything you need to streamline your legal research and case management
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
            {features.map((feature, index) => (
              <FeatureCard key={index} feature={feature} index={index} />
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-16 px-4 sm:px-6 lg:px-8 bg-white dark:bg-gray-900">
        <div className="max-w-4xl mx-auto">
          <div className="text-center mb-12">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 dark:text-white mb-4">
              Frequently Asked Questions
            </h2>
            <p className="text-neutral-500 dark:text-neutral-400 text-lg">
              Everything you need to know about ModulawAI
            </p>
          </div>
          
          <div className="grid divide-y divide-neutral-200 dark:divide-neutral-700 mx-auto mt-8">
            {faqItems.map((item, index) => (
              <FAQItem key={index} {...item} index={index} />
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 sm:py-20 px-4 sm:px-6 lg:px-8 bg-blue-700 dark:bg-blue-800">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-white">
            Join Nigeria's Leading Law Firms Using ModulawAI
          </h2>
          <p className="text-base sm:text-lg mb-8 text-gray-100">
            Transform your legal practice with AI-powered research, analysis, and insights. Start your free trial today and experience the future of legal technology.
          </p>
          <button 
            onClick={() => navigate('/signup')}
            className="bg-white text-blue-700 hover:bg-gray-100 dark:hover:bg-gray-200 
              px-6 sm:px-8 py-3 rounded-lg text-base sm:text-lg transition-colors duration-200 
              shadow-lg hover:shadow-xl"
          >
            Start Your Free Trial Now
          </button>
          <p className="text-sm text-gray-100 mt-4">
            No credit card required
          </p>
        </div>
      </section>
    </div>
  );
};

export default HomePage;