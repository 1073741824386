import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// This component will scroll the window to the top whenever the route changes
// Optimized to prevent unnecessary renders and improve performance
function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPathRef = useRef(pathname);

  useEffect(() => {
    // Only scroll if the path has actually changed
    if (prevPathRef.current !== pathname) {
      // Use requestAnimationFrame for smoother scrolling
      requestAnimationFrame(() => {
        // Use smooth scrolling on desktop, instant on mobile for better performance
        const isMobile = window.innerWidth < 768;
        
        if (isMobile) {
          window.scrollTo(0, 0);
        } else {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
      });
      
      prevPathRef.current = pathname;
    }
  }, [pathname]);

  // Return null to avoid rendering anything to the DOM
  return null;
}

export default ScrollToTop; 