import React, { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { store } from './store/index.js';
import { ThemeProvider } from './providers/ThemeProvider';
import { fetchProfile, loginSuccess, fetchCredits } from './store/authSlice';
import Layout from './components/Layout';
import ErrorBoundary from './components/ErrorBoundary';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './components/ScrollToTop.js';

// Eagerly loaded components (critical path)
import LoginForm from './components/LoginForm.js';
import SignupForm from './components/SignupForm.js';
import HomePage from './components/Homepage.js';
import PublicChat from './components/PublicChat';

// Lazily loaded components (non-critical path)
const Chat = lazy(() => import('./components/Chat.js'));
const Profile = lazy(() => import('./components/Profile.js'));
const PricingPage = lazy(() => import('./components/PricingPage.js'));
const Solutions = lazy(() => import('./components/Solutions.js'));
const SolutionPage = lazy(() => import('./components/solutions/SolutionPage.js'));
const ProtectedRoute = lazy(() => import('./components/ProtectedRoute.js'));
const EmailVerification = lazy(() => import('./components/EmailVerification'));
const PaymentCallback = lazy(() => import('./components/PaymentCallback'));
const ContactUs = lazy(() => import('./components/ContactUs'));
const CaseManager = lazy(() => import('./components/caseManager'));
const AcceptInvitation = lazy(() => import('./components/workspace/AcceptInvitation'));
const ResetPassword = lazy(() => import('./components/ResetPassword'));
const Investors = lazy(() => import('./components/Investors.js'));
const AboutUs = lazy(() => import('./components/AboutUs.js'));
const WorkspaceProvider = lazy(() => import('./providers/WorkspaceProvider').then(module => ({ default: module.WorkspaceProvider })));
const CaseManagement = lazy(() => import('./components/products/CaseManagement.js'));
const LegalAssistant = lazy(() => import('./components/products/LegalAssistant.js'));

// Loading fallback component
const LoadingFallback = () => (
  <div className="flex items-center justify-center h-screen">
    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-700"></div>
  </div>
);

function AppContent() {
  const dispatch = useDispatch();
  const { token, user, isInitialized } = useSelector(state => state.auth);
  const isAuthenticated = !!token && !!user;

  useEffect(() => {
    if (token && !user) {
      dispatch(fetchProfile());
    }
  }, [dispatch, token, user]);

  useEffect(() => {
    if (token) {
      dispatch(fetchCredits());
    }
  }, [dispatch, token]);

  // Don't render protected routes until auth is initialized
  if (!isInitialized) {
    return <LoadingFallback />;
  }

  return (
    <div className="App">
      <ErrorBoundary>
        <ScrollToTop />
        <Suspense fallback={<LoadingFallback />}>
          <Routes>
            {/* Public routes - always available */}
            <Route path="/share/:shareId" element={<PublicChat />} />
            <Route path="/login" element={
              <Layout>
                <LoginForm />
              </Layout>
            } />
            <Route path="/" element={
              <Layout>
                <HomePage />
              </Layout>
            } />
            <Route path="/signup" element={
              <Layout>
                <SignupForm />
              </Layout>
            } />
            <Route path="/solutions" element={
              <Layout>
                <Solutions />
              </Layout>
            } />
            <Route path="/solutions/:solutionId" element={
              <Layout>
                <SolutionPage />
              </Layout>
            } />
            <Route path="/pricing" element={
              <Layout>
                <PricingPage />
              </Layout>
            } />
            <Route path="/verify-email" element={
              <Layout>
                <EmailVerification />
              </Layout>
            } />
            <Route path="/emailverification" element={
              <Layout>
                <EmailVerification />
              </Layout>
            } />
            <Route path="/payment/callback" element={
              <Layout>
                <PaymentCallback />
              </Layout>
            } />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/workspace/join/:token" element={<AcceptInvitation />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/investors" element={
              <Layout>
                <Investors />
              </Layout>
            } />
            <Route path="/about" element={
              <Layout>
                <AboutUs />
              </Layout>
            } />
            <Route path="/products/case-management" element={
              <Layout>
                <CaseManagement />
              </Layout>
            } />
            <Route path="/products/legal-assistant" element={
              <Layout>
                <LegalAssistant />
              </Layout>
            } />

            {/* Protected routes - only loaded if authenticated */}
            {isAuthenticated ? (
              <>
                <Route path="/chat/*" element={<Chat />} />
                <Route path="/profile" element={
                  <Layout>
                    <Profile />
                  </Layout>
                } />
                <Route path="/case-manager" element={
                  <Layout showNavbar={false}>
                    <WorkspaceProvider>
                      <CaseManager />
                    </WorkspaceProvider>
                  </Layout>
                } />
              </>
            ) : (
              <Route path="/chat/*" element={<Navigate to="/login" replace />} />
            )}

            {/* Fallback route */}
            <Route path="*" element={<Navigate to={isAuthenticated ? "/chat" : "/"} replace />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

// Add isInitialized to auth state
const InitializeAuth = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    const initializeAuth = async () => {
      try {
        dispatch({ type: 'auth/initializeStart' });
        const persistedAuth = localStorage.getItem('auth');
        if (persistedAuth) {
          const authData = JSON.parse(persistedAuth);
          if (authData.user && authData.token) {
            dispatch(loginSuccess(authData));
          }
        }
        dispatch({ type: 'auth/initializeComplete' });
      } catch (error) {
        console.error('Error initializing auth:', error);
        dispatch({ type: 'auth/initializeComplete' });
      }
    };

    initializeAuth();
  }, [dispatch]);

  return null;
};

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider>
          <BrowserRouter>
            <InitializeAuth />
            <AppContent />
          </BrowserRouter>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
